import React from "react"
import Helmet from "react-helmet"
import classNames from "classnames"

import { makeStyles } from "@material-ui/core/styles"
import Layout from "src/components/Layout"

import GlobalBaseState from "src/assets/js/GlobalBaseState.js"

// Sections for this page
import FeaturedProperties from "./Sections/FeaturedProperties"

import styles from "src/assets/jss/material-kit-react/views/homePage"
const useStyles = makeStyles(styles)

export default function HomePage({ pageContent, ...rest }) {
  const pageClasses = useStyles()

  const [globalState, setGlobalState] = React.useState(GlobalBaseState)
  const [fullViewportIndex, setFullViewportIndex] = React.useState(null)

  const handleToggleFullViewport = propertyIndex => {
    setFullViewportIndex(fullViewportIndex === null ? propertyIndex : null)
  }
  console.log(rest.location)

  return (
    <Layout
      pageClasses={pageClasses}
      scaffolding={pageContent.scaffolding}
      globalState={globalState}
      {...rest}
    >
      {(isLandscape /** isLandscape passed as method parameter */) => (
        <>
          <Helmet>
            <body
              className={classNames(
                pageClasses.body,
                "homepage-wrapper",
                fullViewportIndex === null ? null : "full-viewport-mode"
              )}
            />
          </Helmet>
          <FeaturedProperties
            pageContent={pageContent}
            siteVariables={rest.siteVariables}
            isLandscape={isLandscape}
            fullViewportIndex={fullViewportIndex}
            globalState={globalState}
            handleToggleFullViewport={handleToggleFullViewport}
          />
        </>
      )}
    </Layout>
  )
}
