import defaultComponentStyles from "src/assets/jss/material-kit-react/defaultComponentStyles"
import {
  getCalcRem,
  secondaryBgColor,
  tertiaryBgColor,
  primaryTextColor,
  defaultDesktopFontSize,
  defaultMobileFontSize,
  primaryBgColor,
  secondaryTextColor,
} from "../../../../material-kit-react"

const featuredPropertiesStyles = theme => ({
  ...defaultComponentStyles,
  featuredPropertiesWrapper: {
    minWidth: "100%",
    paddingLeft: "0",
    paddingRight: "0",
    "& .cardBody": {
      position: "relative",
      "& .info-overlay": {
        margin: "0 auto",
      },
    },
    "& .slick-arrow": {
      transform: "none !important",
      top: "0 !important",
      opacity: "1 !important",
      height: "90% !important",
      width: "60px !important",
      /**
      [theme.breakpoints.up("md")]: {
        width: "96px !important"
      },
       */
      "&::before": {
        background: secondaryBgColor,
        color: primaryTextColor,
        height: "60px",
        width: "30px",
        borderRadius: "30px",
        fontSize: defaultMobileFontSize,
        /** border: "2px solid " + primaryBgColor, */
        lineHeight: "60px",
        [theme.breakpoints.up("md")]: {
          fontSize: defaultDesktopFontSize,
          /**
          height: "96px",
          width: "96px",
          borderRadius: getCalcRem(60),
          lineHeight: getCalcRem(96),
          */
        },
      },
    },
    "& .info-overlay-open .image-gallery-left-nav, & .info-overlay-open .image-gallery-right-nav, & .info-overlay-open .image-gallery-fullscreen-button": {
      display: "none !important",
    },

    "& .cardFooter": {
      padding: theme.spacing(1),
      "& nav": {
        display: "flex",
        flexDirection: "row",
        background: secondaryBgColor,
        justifyContent: "space-between",
        margin: "0 auto",
        "&.menuLinks_3": {
          width: "325px",
          [theme.breakpoints.up("sm")]: {
            width: "450px",
          },
          [theme.breakpoints.up("md")]: {
            width: "500px",
          },
          "& .listings-sold": {
            backgroundColor: tertiaryBgColor,
          },
        },
        "&.menuLinks_2": {
          width: "200px",
          [theme.breakpoints.up("sm")]: {
            width: "275px",
          },
          [theme.breakpoints.up("md")]: {
            width: "300px",
          },
          "& .listings-sold": {
            background:
              "linear-gradient(to right, #f1d9bb," + tertiaryBgColor + ")",
          },
        },
        "&.menuLinks_1": {},
        "& .MuiListItem-root": {
          padding: "0",
        },
        "& .MuiButton-root": {
          justifyContent: "center",
          background: tertiaryBgColor,
          borderRadius: "30px",
          padding: "4px 12px",
          width: "auto",
          margin: "0",
          textDecoration: "none !important",
          [theme.breakpoints.up("sm")]: {
            padding: "4px 16px",
          },
          "& span": {
            textTransform: "lowercase",
            fontSize: getCalcRem(13),
            color: secondaryBgColor,
            [theme.breakpoints.up("sm")]: {
              fontSize: getCalcRem(18),
            },
            [theme.breakpoints.up("md")]: {
              fontSize: getCalcRem(20),
            },
          },
        },
        "& .listings-active": {
          background:
            "linear-gradient(to right, #f1d9bb," + tertiaryBgColor + ")",
        },

        "& .listings-sold": {
          backgroundColor: tertiaryBgColor,
        },
        "& .listings-renovations": {
          background:
            "linear-gradient(to right, " + tertiaryBgColor + ",#f1d9bb)",
        },
      },
    },
  },
})

export default featuredPropertiesStyles
