import {
  secondaryBgColor,
  tertiaryBgColor,
  defaultXSMobileFontSize,
  getCalcRem,
  defaultDesktopFontSize,
  defaultMobileFontSize,
  primaryTextColor,
} from "src/assets/jss/material-kit-react.js"
import { createMuiTheme } from "@material-ui/core/styles"

const theme = createMuiTheme()

const propertyStyles = {
  propertyWrapper: {
    padding: "0 !important",
    position: "relative",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",

    "& .MuiIconButton-root": {
      padding: "0",
    },

    "&.info-overlay-open .property-bottom-controls-wrapper": {
      display: "none !important",
    },
    "&.info-overlay-open .info-wrapper": {
      display: "block",
    },

    "& .property-bottom-controls-wrapper": {
      position: "absolute",
      bottom: "4px",
      width: "100%",
      height: "42px",
      display: "flex",
      flexDirection: "row",
      padding: "0 " + getCalcRem(16),
      [theme.breakpoints.up("md")]: {
        bottom: "23px",
      },

      "& .background-transparency": {
        position: "absolute",
        height: "100%",
        width: "100%",
        backgroundColor: secondaryBgColor,
        opacity: ".6",
        zIndex: "1",
      },
      "& *": {
        zIndex: "2",
        color: primaryTextColor,
        fontSize: defaultDesktopFontSize,
        fontWeight: "400",
        letterSpacing: "0.00938em",
        lineHeight: getCalcRem(22),
        whiteSpace: "nowrap",
        [theme.breakpoints.up("md")]: {
          fontSize: defaultDesktopFontSize,
          lineHeight: getCalcRem(32),
        },
      },
      "&.settingsOpen": {
        display: "none",
      },
      "& .icon-fullscreen-wrapper, & .icon-fullscreen-wrapper *, & .icon-info-toggle-wrapper,  & .icon-info-toggle-wrapper *": {
        height: getCalcRem(32),
        width: getCalcRem(32),
        [theme.breakpoints.up("md")]: {
          height: getCalcRem(42),
          width: getCalcRem(42),
        },
      },
      "& .icon-info-toggle-wrapper": {
        "& span": {
          fontSize: defaultDesktopFontSize,
          filter: "drop-shadow(0 2px 2px #1a1a1a)",
          [theme.breakpoints.up("md")]: {
            fontSize: getCalcRem(32),
          },
        },

        "&:hover span": {
          transform: "scale(1.1)",
        },
      },
      "& .icon-fullscreen-wrapper": {
        justifySelf: "flex-end",
        marginLeft: "auto",
        "& button": {
          backgroundColor: tertiaryBgColor,
          //opacity: ".8"
          "& svg": {
            fill: secondaryBgColor,
            border: "3px solid " + secondaryBgColor,
            borderRadius: "21px",
          },
        },
      },
      "& .icon-fullscreen-wrapper:hover": {
        "& button": {
          backgroundColor: secondaryBgColor,
          //opacity: ".8"
          "& svg": {
            fill: primaryTextColor,
            borderColor: tertiaryBgColor,
          },
        },
      },
    },
    "& .info-wrapper": {
      display: "none",
      position: "absolute",
      top: "0px",
      width: "100%",
      height: "100%",

      "& .background-transparency": {
        position: "absolute",
        height: "100%",
        width: "100%",
        backgroundColor: secondaryBgColor,
        opacity: ".6",
        zIndex: "1",
      },

      "& .content-wrapper": {
        position: "absolute",
        zIndex: "2",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        padding: getCalcRem(16),
        "&  *": {
          fontWeight: "500",
          [theme.breakpoints.down("xs")]: {
            fontSize: defaultXSMobileFontSize,
          },
        },
        [theme.breakpoints.up("md")]: {
          padding: getCalcRem(16),
        },

        "&.settingsOpen": {
          display: "none",
        },
        "& .property-price, & .agent-role": {
          /**
        display: "none",
        [theme.breakpoints.up("md")]: {
          display: "inline"
        }
      */
        },
        "& .icons-quote-wrapper": {
          display: "flex",
          flexDirection: "row",
          height: "40%",
          width: "100%",
          [theme.breakpoints.up("sm")]: {
            height: "60%",
          },
        },

        "& .icon-close-wrapper": {
          justifySelf: "flex-end",
          marginLeft: "auto",
          "& button": {
            backgroundColor: "transparent",
            "&:hover": {
              backgroundColor: "transparent",
            },
          },
          "& span": {
            fontSize: "2.5rem",
            filter: "drop-shadow(0 2px 2px #1a1a1a)",
          },
          "&:hover span": {
            transform: "scale(1.1)",
          },
        },
        "& .icon-status-wrapper": {
          justifySelf: "flex-start",
          height: getCalcRem(64),
          width: getCalcRem(64),
          borderRadius: "42px",
          [theme.breakpoints.up("md")]: {
            height: getCalcRem(84),
            width: getCalcRem(84),
          },
        },

        "& .quote-wrapper": {
          maxHeight: "75px",
          overflowY: "scroll",
          paddingRight: theme.spacing(1),
          marginRight: theme.spacing(1),
          [theme.breakpoints.up("sm")]: {
            marginTop: theme.spacing(4),
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
          },
          [theme.breakpoints.up("sm")]: {
            maxHeight: "200px",
            marginTop: theme.spacing(7),
            paddingLeft: theme.spacing(4),
          },
          "& *": {
            lineHeight: getCalcRem(22),
            whiteSpace: "normal !important",
            [theme.breakpoints.up("md")]: {
              lineHeight: getCalcRem(32),
            },
          },
          "& .quote-text": {
            margin: "0 0 10px",
            fontStyle: "italic",
            letterSpacing: "0.00938em",
          },
          "& .quote-author": {
            letterSpacing: "0.00938em",
            lineHeight: getCalcRem(22),
            [theme.breakpoints.up("md")]: {
              lineHeight: getCalcRem(32),
            },
          },
          "& .fadeout": {
            position: "relative",
            bottom: "4em",
            height: "4em",
            background:
              "-webkit-linear-gradient(rgba(255, 255, 255, 0) 0%,rgba(255, 255, 255, 1) 100%)",
            backgroundImage:
              "-moz-linear-gradient(rgba: (255, 255, 255, 0) 0%, rgba: (255, 255, 255, 1) 100%)",
            backgroundImage:
              "-o-linear-gradient(rgba: (255, 255, 255, 0) 0%, rgba: (255, 255, 255, 1) 100%)",
            backgroundImage:
              "-ms-linear-gradient(rgba: (255, 255, 255, 0) 0%, rgba: (255, 255, 255, 1) 100%)",
            backgroundImage:
              "linear-gradient(rgba(255, 255, 255, 0) 0%,rgba(255, 255, 255, 1) 100%)",
          },
        },
        "& .property-details-wrapper": {
          height: "50%",
          paddingTop: ".5rem",
          display: "flex",
          flexDirection: "row",
          [theme.breakpoints.up("md")]: {
            paddingTop: theme.spacing(1),
          },

          "& .column": {
            display: "flex",
            flexDirection: "column",
          },
          "& .labels-column": {
            padding: "0 16px 0 0",
            width: "45%",
            textAlign: "right",
          },
          "& .values-column": {
            marginLeft: "auto",
            marginRight: "auto",
            "& span": {
              display: "block",
              marginBottom: theme.spacing(1),
              textAlign: "center",
              lineHeight: getCalcRem(22),

              [theme.breakpoints.up("md")]: {
                lineHeight: getCalcRem(32),
              },
              "@media (max-width: 400px)": {
                lineHeight: getCalcRem(22),
              },
              [theme.breakpoints.down("xs")]: {
                marginBottom: theme.spacing(0.25),
              },
            },
          },
          "& .property-listing-link": {
            "& a": {
              display: "flex",
              justifyContent: "center",
              "& span": {
                display: "inline !important",
              },
              "& .link-arrow": {
                fontSize: "1.5rem",
              },
            },
          },
        },
      },
    },
  },
}

export default propertyStyles
