import React, { useRef, useState, useEffect } from "react"
import classNames from "classnames"

import { useSwipeable } from "react-swipeable"

// react component for creating beautiful carousel
import ImageGallery from "react-image-gallery"

import ThumbSwipeHelpers from "src/assets/js/ThumbSwipeHelpers.js"

// custom components
import Property from "./Property"
import { set } from "dot-prop"

export default function FeaturedGalleryCarousel({
  properties,
  globalState,
  ...rest
}) {
  let galleryRef = null

  const thumbnailSwipeHandlers = useSwipeable({
    onSwiped: () => {}, // After any swipe   (SwipeEventData) => void
    onSwipedLeft: function(data) {
      ThumbSwipeHelpers.handleSlideMove(data)
    }, // After LEFT swipe  (SwipeEventData) => void
    onSwipedRight: function(data) {
      ThumbSwipeHelpers.handleSlideMove(data)
    }, // After RIGHT swipe (SwipeEventData) => void
    onSwipedUp: () => {}, // After UP swipe    (SwipeEventData) => void
    onSwipedDown: () => {}, // After DOWN swipe  (SwipeEventData) => void
    onSwiping: () => {}, // During swiping    (SwipeEventData) => void
    onTap: () => {}, // After a tap       ({ event }) => void
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  })

  useEffect(() => {
    if (!globalState.isBrowser) return

    ThumbSwipeHelpers.setGalleryRef(galleryRef)
    ThumbSwipeHelpers.setThumbs("image-gallery-thumbnail")
    ThumbSwipeHelpers.setThumbSliderRef("image-gallery-thumbnails-container")
    ThumbSwipeHelpers.setSizes()
    ThumbSwipeHelpers.setSwipeableHandlers(thumbnailSwipeHandlers)

    function handleResize() {
      ThumbSwipeHelpers.setSizes()
    }
    window.addEventListener("resize", handleResize)

    return _ => {
      window.removeEventListener("resize", handleResize)
    }
  })
  const getFeaturedImage = p => {
    if (p.media_featuredImage) return p.media_featuredImage.file.url
    else if (p.media_photos) return p.media_photos[0].file.url
    else return null
  }

  const renderProperty = item => {
    return (
      <Property
        className="slick-image"
        property={item.property}
        globalState={globalState}
        galleryRef={galleryRef}
        {...rest}
      />
    )
  }

  const propertiesWithImages = properties.filter((p, i) => {
    p.featuredImage = getFeaturedImage(p)
    return p.featuredImage !== null
  })

  const items = propertiesWithImages.map((p, i) => {
    return {
      original: p.featuredImage,
      thumbnail: p.featuredImage,
      property: p,
      index: i,
      renderItem: renderProperty
    }
  })

  return (
    <div>
      <ImageGallery
        ref={i => (galleryRef = i)}
        items={items}
        showPlayButton={false}
        autoPlay={true}
        slideInterval={4000}
      />
    </div>
  )
}
