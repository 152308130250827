import defaultPageStyles from "src/assets/jss/material-kit-react/defaultPageStyles"

const homePageStyles = theme => ({
  body: {
    ...defaultPageStyles,
    "& .cardBody": {
    },
    "& .icon-fullscreen-exit": {
      display: "none"
    },
    "&.full-viewport-mode": {
      "& .icon-fullscreen-exit": {
        display: "inline-block"
      },
      "& .icon-fullscreen": {
        display: "none"
      },
      "& header, & .cardHeader, & .cardBody, & footer": {
        display: "none"
      },
      "& .slick-slider": {
        width: "100%"
      }
    }
  }
})

export default homePageStyles
