import React from "react"
import classNames from "classnames"
import { makeStyles } from "@material-ui/core/styles"

// material-ui components
import { List, MenuItem } from "@material-ui/core"

// custome components
import Card from "src/components/Card/Card.js"
import CardBody from "src/components/Card/CardBody.js"
import CardHeader from "src/components/Card/CardHeader.js"
import CardFooter from "src/components/Card/CardFooter.js"
import Button from "src/components/CustomButton"

//import PropertyGalleryList from "src/components/PropertyGallery/PropertyGalleryList"
import FeaturedGalleryCarousel from "src/components/PropertyGallery/FeaturedGalleryCarousel"

import cardStyles from "src/assets/jss/material-kit-react/components/cardStyle.js"
import styles from "src/assets/jss/material-kit-react/views/homePage/sections/featuredPropertiesStyles"

const useFeaturedStyles = makeStyles(styles)
const useCardStyles = makeStyles(cardStyles)

const FeaturedProperties = ({
  pageContent,
  globalState,
  fullViewportIndex,
  isLandscape,
  ...rest
}) => {
  const classes = useFeaturedStyles()
  const cardClasses = useCardStyles()

  const {
    headerText_page,
    properties_featured,
    ref_listingsNavPages,
  } = pageContent

  return (
    <Card className={classes.featuredPropertiesWrapper}>
      <CardHeader color="primary">{headerText_page}</CardHeader>
      <CardBody
        className={classNames(
          "cardBody",
          classes.cardBody,
          cardClasses.cardBody
        )}
      >
        <FeaturedGalleryCarousel
          properties={properties_featured}
          globalState={globalState}
          {...rest} /* passing down rest values from main component */
        />
      </CardBody>
      <CardFooter className="cardFooter">
        <List
          component="nav"
          aria-label="homepage listings navigation"
          dense={true}
          className={"menuLinks_" + ref_listingsNavPages.length}
        >
          {ref_listingsNavPages.map((p, i) => {
            return (
              <MenuItem key={i}>
                <Button
                  className={p.scaffolding.text_slug}
                  href={"/" + p.scaffolding.text_slug}
                  color="transparent"
                  title={"Navigate to: " + p.headerText_page + " page"}
                  data-analytics-label={
                    "Homepage " + p.headerText_page + " link"
                  }
                >
                  {p.headerText_page}
                </Button>
              </MenuItem>
            )
          })}
        </List>
      </CardFooter>
    </Card>
  )
}

export default FeaturedProperties
