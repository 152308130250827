import React from "react"
import classNames from "classnames"
import { makeStyles } from "@material-ui/core/styles"

// material-ui components
import { IconButton, Typography } from "@material-ui/core"

// @material-ui/icons
import CloseIcon from "@material-ui/icons/Close"
import ArrowRight from "@material-ui/icons/ArrowRight"

// custom components

import styles from "src/assets/jss/material-kit-react/components/propertyStyles.js"

const useStyles = makeStyles(styles)

export default function Property({ property, galleryRef, index, globalState }) {
  const classes = useStyles()
  const {
    text_status,
    boolean_remodel,
    media_featuredImage,
    media_photos,
    refTestimonial,
  } = property

  const [infoOverlayOpen, setInfoOverlayOpen] = React.useState(null)
  const imageGallery = globalState.isBrowser
    ? document.getElementsByClassName("image-gallery")
    : null

  const handleInfoOverlayClose = () => {
    setInfoOverlayOpen(null)
    if (imageGallery && imageGallery.length > 0) {
      imageGallery[0].classList.remove("info-overlay-open")
      if (galleryRef) galleryRef.play()
    }
  }
  const handleToggleInfoOverlay = () => {
    setInfoOverlayOpen(!infoOverlayOpen)
    if (imageGallery && imageGallery.length > 0) {
      imageGallery[0].classList.add("info-overlay-open")
      if (galleryRef) galleryRef.pause()
    }
  }

  const getFeaturedImage = () => {
    if (media_featuredImage !== null) return media_featuredImage.file.url
    else return media_photos[0].file.url
  }

  const PropertyStatus = () => {
    return <span className="property-status">{text_status}</span>
  }

  return (
    <>
      {media_photos && (
        <div
          className={classNames(
            "property-wrapper",
            classes.propertyWrapper,
            infoOverlayOpen ? "info-overlay-open" : null
          )}
        >
          <img className="image-gallery-image" src={getFeaturedImage()} />
          <div className={"property-bottom-controls-wrapper "}>
            <IconButton
              className="icon-info-toggle-wrapper"
              aria-label="View property information."
              onClick={handleToggleInfoOverlay}
            >
              i
            </IconButton>
          </div>
          <div className={classNames("info-wrapper")}>
            <div className="background-transparency"></div>
            <div className="content-wrapper">
              <div className="icons-quote-wrapper">
                {refTestimonial && (
                  <div className="quote-wrapper">
                    <Typography component="span" className={"quote-text"}>
                      "{refTestimonial.text_testimonial.text_testimonial}"
                    </Typography>
                    <Typography component="span" className={"quote-author"}>
                      &nbsp;- {refTestimonial.text_clientPublicName}
                    </Typography>
                  </div>
                )}
                <div className="icon-button-wrapper icon-close-wrapper">
                  <IconButton
                    aria-label="Close property information overlay."
                    onClick={handleInfoOverlayClose}
                  >
                    x
                  </IconButton>
                </div>
              </div>
              <div className="property-details-wrapper">
                {/**
          <div className="column labels-column">
            <span className="label">Status:</span>
            <span className="label">Role:</span>
            <span className="label">Size:</span>
            <span className="label">Location:</span>
            <span className="label">Price:</span>
          </div>
           */}
                <div className="column values-column">
                  <span>
                    {property.text_streetAddress + " | " + property.text_city}
                  </span>
                  <span>
                    {property.text_bedrooms +
                      " Bd | " +
                      property.text_bathrooms +
                      " Bath"}
                  </span>
                  <span>${property.text_price}</span>
                  <PropertyStatus />

                  <div className="property-listing-link">
                    <a
                      href={
                        (text_status === "Available"
                          ? "/listings-active/#"
                          : "/listings-sold/#") +
                        encodeURIComponent(property.text_streetAddress)
                      }
                      target="_self"
                      rel=""
                      title="Navigate to the property detail view on the listings page."
                    >
                      <span>See Details</span>
                      <ArrowRight className="link-arrow" fontSize="small" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
